/**
 * @generated SignedSource<<ad2139dd5e1b54b9b2d6cb827fc0b454>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PdpSharedSitemapLinks_item$data = {
  readonly sitemapLinks: ReadonlyArray<{
    readonly path: string | null;
    readonly text: string | null;
  }> | null;
  readonly " $fragmentType": "PdpSharedSitemapLinks_item";
};
export type PdpSharedSitemapLinks_item$key = {
  readonly " $data"?: PdpSharedSitemapLinks_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"PdpSharedSitemapLinks_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PdpSharedSitemapLinks_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LinkData",
      "kind": "LinkedField",
      "name": "sitemapLinks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "791039eb83be8bc90e6c189964c0932a";

export default node;
