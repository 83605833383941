import { FC, useRef, useEffect, MouseEventHandler } from 'react';
import { useIntl, defineMessages } from 'dibs-react-intl';
import scrollIntoView from 'scroll-into-view';

import { SeoLink, LinkData } from '../SeoLink';
import { STATIC_BREADCRUMBS_SELECTOR_CLASSNAMES } from './breadcrumbsClassSelectors';

import styles from './main.scss';

export type BreadcrumbsProps = {
    breadcrumbs: (LinkData & { text: string })[];

    animate?: boolean;
    duration?: number;
    onClick?: MouseEventHandler;
    isLastItemActive?: boolean;
};

const scrollToEnd = (el: HTMLElement, duration: number = 1000): void => {
    const lastItem = el.querySelector(`.${styles.lastItem}`);
    scrollIntoView(lastItem, {
        time: duration,
    });
};

const messages = defineMessages({
    label: { id: 'Breadcrumbs.label', defaultMessage: 'Breadcrumbs' },
});

export const Breadcrumbs: FC<BreadcrumbsProps> = ({
    breadcrumbs = [],
    onClick = () => {},
    isLastItemActive = false,
    animate,
    duration,
}) => {
    const intl = useIntl();
    const wrapperRef = useRef<HTMLOListElement | null>(null);

    useEffect(() => {
        if (animate && wrapperRef.current) {
            scrollToEnd(wrapperRef.current, duration);
        }
    }, [animate, duration, wrapperRef]);

    return (
        <nav aria-label={intl.formatMessage(messages.label)}>
            <ol ref={wrapperRef} className={styles.wrapper} data-tn="breadcrumbs">
                {breadcrumbs.map((breadcrumb, i) => {
                    const { text, path, isLinkable } = breadcrumb;
                    const key = `${text}${i}`;
                    const lastItem = breadcrumbs.length === i + 1;
                    return lastItem && !isLastItemActive ? (
                        <li
                            key={key}
                            data-tn="breadcrumb-item-last"
                            className={`${styles.lastItem} ${STATIC_BREADCRUMBS_SELECTOR_CLASSNAMES.ITEM_LAST}`}
                        >
                            {text}
                        </li>
                    ) : (
                        <li className={styles.item} key={key}>
                            <SeoLink
                                dataTn="breadcrumb-item"
                                className={STATIC_BREADCRUMBS_SELECTOR_CLASSNAMES.ITEM}
                                linkData={{
                                    path,
                                    isLinkable,
                                }}
                                onClick={onClick}
                            >
                                {text}
                            </SeoLink>
                        </li>
                    );
                })}
            </ol>
        </nav>
    );
};
