import { FC } from 'react';
import classnames from 'classnames';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import LazyImage from 'dibs-react-lazy-image';
import { Link } from 'dibs-elements/exports/Link';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { trackArticleClick } from './tracking';
import { getSrcsetString } from 'dibs-image-utils/exports/srcSet';

import styles from './styles/ArticleLink.scss';
import dibsCss from 'dibs-css';

const getHeaderId = (index: number): string => `wp-article-header-${index}`;
const getDescriptionId = (index: number): string => `wp-article-description-${index}`;
const LAZY_IMAGE_VERTICAL_OFFSET = 500;

import { ArticleLink_article$data } from './__generated__/ArticleLink_article.graphql';

type Props = {
    article: ArticleLink_article$data;
    index: number;
    stacked?: boolean;
    hidden?: boolean;
};

const ArticleLink: FC<Props> = props => {
    const { article, index, stacked, hidden } = props;
    const descriptionId = getDescriptionId(index);

    return (
        <HeadingLevel>
            {Heading => (
                <Link
                    ariaLabel={`Read more about ${article.title}`}
                    ariaDescribedBy={`${getHeaderId(index)},${descriptionId}`}
                    buyerLinkType="standardLinkNoEffect"
                    underline="none"
                    href={article.link}
                    target="_blank"
                    dataTn={`wp-article-link-${index}`}
                    className={classnames(styles.article, {
                        [styles.stacked]: stacked,
                        [styles.hidden]: hidden,
                    })}
                    onClick={() => trackArticleClick(article)}
                >
                    <div className={styles.articleElement}>
                        <LazyImage
                            offsetVertical={LAZY_IMAGE_VERTICAL_OFFSET}
                            placeholderClass={classnames(dibsCss.minWFull, dibsCss.minHFull)}
                        >
                            <img
                                className={styles.featuredImg}
                                src={article.imageUrl.full}
                                srcSet={getSrcsetString(article.imageUrl.full)}
                                sizes="(max-width: 320px) 100vw, 25vw"
                                alt={article.title}
                            />
                        </LazyImage>
                    </div>
                    <div className={styles.articleElement}>
                        <Heading
                            className={styles.header}
                            id={getHeaderId(index)}
                            dangerouslySetInnerHTML={{ __html: article.title }}
                        />
                        <p
                            className={styles.body}
                            id={descriptionId}
                            dangerouslySetInnerHTML={{
                                __html: article.subtitle,
                            }}
                        />
                    </div>
                </Link>
            )}
        </HeadingLevel>
    );
};

export default createFragmentContainer(ArticleLink, {
    article: graphql`
        fragment ArticleLink_article on WordpressArticleType {
            title
            imageUrl {
                full
            }
            subtitle
            link
            source
        }
    `,
});
