/**
 * @generated SignedSource<<ee1d6a56975d2101fc36b0a939a33f82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedPopularSearchesLink_popularSearchesLink$data = {
  readonly path: string | null;
  readonly text: string | null;
  readonly " $fragmentType": "SharedPopularSearchesLink_popularSearchesLink";
};
export type SharedPopularSearchesLink_popularSearchesLink$key = {
  readonly " $data"?: SharedPopularSearchesLink_popularSearchesLink$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedPopularSearchesLink_popularSearchesLink">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SharedPopularSearchesLink_popularSearchesLink",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "type": "LinkData",
  "abstractKey": null
};

(node as any).hash = "2efa0c06bdb8e8b688963baa45fbfe44";

export default node;
